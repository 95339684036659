html {
  font-family: "Architects Daughter", sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
body {
  background-color: radial-gradient(#d3d3d3, #696969);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-image 5s ease-in-out;
}
h1 {
  color: #000;
  font-family: "Cabin Sketch", sans-serif;
  font-size: 2.75rem;
  font-weight: 300;
}
h2,
h3,
h4,
h5,
h6 {
  color: #1a237e;
  font-family: "Cabin Sketch", sans-serif;
  font-weight: 300;
}
h2 {
  font-size: 2.3125em;
}
main {
  background-color: #fff;
  background-image: linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px), linear-gradient(#eee 0.15em, transparent 0.15em);
  background-size: 1px, auto 20px;
  background-repeat: repeat-y, repeat;
  background-position: 10% 0, 0 0, 0 0;
  box-shadow: rgba(0,0,0,0.5) 0px 0px 6px 0px;
  margin: 2em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero h1 {
  font-size: 6em;
}
